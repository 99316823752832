
import { debounce } from 'debounce'

export default {
  name: 'MegaMenuSearch',
  props: {
    placeholder: {
      type: String,
      default: 'Search',
    },

    fitToContent: {
      type: Boolean,
      default: false,
    },

    isSearchInBottomMenu: {
      type: Boolean,
      default: false,
    },

  },

  data () {
    return {
      query: this.$route.query?.q || '',
      page: this.$route.query?.page || 1,
      isOpen: false,
    }
  },

  watch: {
    query () {
      /\/search\/q/.test(this.$route.fullPath)
        ? this.updateRoute(this.query)
        : this.getSearchResults(this.query)
    },
  },

  methods: {
    updateRoute: debounce(function (q) {
      const query = { q }

      if (this.$route.query.sort) {
        query.sort = this.$route.query.sort
      }

      if (this.$route.query.size) {
        query.size = this.$route.query.size
      }

      if (q.length >= 3) {
        this.$router.push({
          path: this.localePath('/search/q'),
          query,
        })
      }
    }, 300),

    getSearchResults: debounce(function (query) {
      this.$store.dispatch('search/getSearchResults',
        {
          query,
          page: +this.$route.query?.page || 1,
          sort: this.$route.query?.sort,
        },
      )
    }, 300),

    onResultsOpen () {
      this.isOpen = true
      this.$nextTick(() => {
        if (this.$css.breakpoints.mdAndDown) {
          this.$refs['input-mobile'].$refs.input.focus()
        }
      })
    },

    onResultsClose (val) {
      this.isOpen = false

      if (val === 'clickedOnProduct') {
        this.$emit('close-search')
      }
    },

    onSubmit (query) {
      this.updateRoute(query)
      this.onResultsClose()
    },
  },
}
