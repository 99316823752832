
import search from '~/mixins/search'

export default {
  name: 'MegaMenuSearchResults',
  mixins: [
    search,
  ],

  props: {
    query: {
      type: String,
      required: true,
    },
  },

  computed: {
    getProducts () {
      return this.$search.products().slice(0, 7)
    },

    isSearchPage () {
      return /\/search\/q/.test(this.$route.path)
    },
  },

  methods: {
    clickedOnProduct () {
      this.$emit('hide-results')
    },

    boldText (text) {
      const regExp = new RegExp(this.query, 'gi')

      return text.replace(regExp, '<strong>$&</strong>')
    },

    // @todo remove after BE fix
    removeHost (url) {
      return `${url.substring(url.indexOf(/p/))}`
    },
  },
}
